/* BASICS */
.CodeMirror {
  /* Set height, width, borders, and global font properties here */
  font-family: monospace;
  height: 300px;
  color: black;
  direction: ltr;
}
/* PADDING */
.CodeMirror-lines {
  padding: 4px 0;
  /* Vertical padding around content */
}
.CodeMirror pre.CodeMirror-line,
.CodeMirror pre.CodeMirror-line-like {
  padding: 0 4px;
  /* Horizontal padding of content */
}
.CodeMirror-scrollbar-filler,
.CodeMirror-gutter-filler {
  background-color: white;
  /* The little square between H and V scrollbars */
}
/* GUTTER */
.CodeMirror-gutters {
  border-right: 1px solid #ddd;
  background-color: #f7f7f7;
  white-space: nowrap;
}
.CodeMirror-linenumber {
  padding: 0 3px 0 5px;
  min-width: 20px;
  text-align: right;
  color: #999;
  white-space: nowrap;
}
.CodeMirror-guttermarker {
  color: black;
}
.CodeMirror-guttermarker-subtle {
  color: #999;
}
/* CURSOR */
.CodeMirror-cursor {
  border-left: 1px solid black;
  border-right: none;
  width: 0;
}
/* Shown when moving in bi-directional text */
.CodeMirror div.CodeMirror-secondarycursor {
  border-left: 1px solid silver;
}
.cm-fat-cursor .CodeMirror-cursor {
  width: auto;
  border: 0 !important;
  background: #7e7;
}
.cm-fat-cursor div.CodeMirror-cursors {
  z-index: 1;
}
.cm-fat-cursor-mark {
  background-color: rgba(20, 255, 20, 0.5);
  -webkit-animation: blink 1.06s steps(1) infinite;
  animation: blink 1.06s steps(1) infinite;
}
.cm-animate-fat-cursor {
  width: auto;
  border: 0;
  -webkit-animation: blink 1.06s steps(1) infinite;
  animation: blink 1.06s steps(1) infinite;
  background-color: #7e7;
}
@-webkit-keyframes blink {
  50% {
    background-color: transparent;
  }
}
@keyframes blink {
  50% {
    background-color: transparent;
  }
}
/* Can style cursor different in overwrite (non-insert) mode */
.cm-tab {
  display: inline-block;
  text-decoration: inherit;
}
.CodeMirror-rulers {
  position: absolute;
  left: 0;
  right: 0;
  top: -50px;
  bottom: 0;
  overflow: hidden;
}
.CodeMirror-ruler {
  border-left: 1px solid #ccc;
  top: 0;
  bottom: 0;
  position: absolute;
}
/* DEFAULT THEME */
.cm-s-default .cm-header {
  color: blue;
}
.cm-s-default .cm-quote {
  color: #090;
}
.cm-negative {
  color: #d44;
}
.cm-positive {
  color: #292;
}
.cm-header,
.cm-strong {
  font-weight: bold;
}
.cm-em {
  font-style: italic;
}
.cm-link {
  text-decoration: underline;
}
.cm-strikethrough {
  text-decoration: line-through;
}
.cm-s-default .cm-keyword {
  color: #708;
}
.cm-s-default .cm-atom {
  color: #219;
}
.cm-s-default .cm-number {
  color: #164;
}
.cm-s-default .cm-def {
  color: #00f;
}
.cm-s-default .cm-variable-2 {
  color: #05a;
}
.cm-s-default .cm-variable-3,
.cm-s-default .cm-type {
  color: #085;
}
.cm-s-default .cm-comment {
  color: #a50;
}
.cm-s-default .cm-string {
  color: #a11;
}
.cm-s-default .cm-string-2 {
  color: #f50;
}
.cm-s-default .cm-meta {
  color: #555;
}
.cm-s-default .cm-qualifier {
  color: #555;
}
.cm-s-default .cm-builtin {
  color: #30a;
}
.cm-s-default .cm-bracket {
  color: #997;
}
.cm-s-default .cm-tag {
  color: #170;
}
.cm-s-default .cm-attribute {
  color: #00c;
}
.cm-s-default .cm-hr {
  color: #999;
}
.cm-s-default .cm-link {
  color: #00c;
}
.cm-s-default .cm-error {
  color: #f00;
}
.cm-invalidchar {
  color: #f00;
}
.CodeMirror-composing {
  border-bottom: 2px solid;
}
/* Default styles for common addons */
div.CodeMirror span.CodeMirror-matchingbracket {
  color: #0b0;
}
div.CodeMirror span.CodeMirror-nonmatchingbracket {
  color: #a22;
}
.CodeMirror-matchingtag {
  background: rgba(255, 150, 0, 0.3);
}
.CodeMirror-activeline-background {
  background: #e8f2ff;
}
/* STOP */
/* The rest of this file contains styles related to the mechanics of
   the editor. You probably shouldn't touch them. */
.CodeMirror {
  position: relative;
  overflow: hidden;
  background: white;
}
.CodeMirror-scroll {
  overflow: scroll !important;
  /* Things will break if this is overridden */
  /* 50px is the magic margin used to hide the element's real scrollbars */
  /* See overflow: hidden in .CodeMirror */
  margin-bottom: -50px;
  margin-right: -50px;
  padding-bottom: 50px;
  height: 100%;
  outline: none;
  /* Prevent dragging from highlighting the element */
  position: relative;
}
.CodeMirror-sizer {
  position: relative;
  border-right: 50px solid transparent;
}
/* The fake, visible scrollbars. Used to force redraw during scrolling
   before actual scrolling happens, thus preventing shaking and
   flickering artifacts. */
.CodeMirror-vscrollbar,
.CodeMirror-hscrollbar,
.CodeMirror-scrollbar-filler,
.CodeMirror-gutter-filler {
  position: absolute;
  z-index: 6;
  display: none;
  outline: none;
}
.CodeMirror-vscrollbar {
  right: 0;
  top: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}
.CodeMirror-hscrollbar {
  bottom: 0;
  left: 0;
  overflow-y: hidden;
  overflow-x: scroll;
}
.CodeMirror-scrollbar-filler {
  right: 0;
  bottom: 0;
}
.CodeMirror-gutter-filler {
  left: 0;
  bottom: 0;
}
.CodeMirror-gutters {
  position: absolute;
  left: 0;
  top: 0;
  min-height: 100%;
  z-index: 3;
}
.CodeMirror-gutter {
  white-space: normal;
  height: 100%;
  display: inline-block;
  vertical-align: top;
  margin-bottom: -50px;
}
.CodeMirror-gutter-wrapper {
  position: absolute;
  z-index: 4;
  background: none !important;
  border: none !important;
}
.CodeMirror-gutter-background {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 4;
}
.CodeMirror-gutter-elt {
  position: absolute;
  cursor: default;
  z-index: 4;
}
.CodeMirror-gutter-wrapper ::selection {
  background-color: transparent;
}
.CodeMirror-gutter-wrapper ::-moz-selection {
  background-color: transparent;
}
.CodeMirror-lines {
  cursor: text;
  min-height: 1px;
  /* prevents collapsing before first draw */
}
.CodeMirror pre.CodeMirror-line,
.CodeMirror pre.CodeMirror-line-like {
  /* Reset some styles that the rest of the page might have set */
  border-radius: 0;
  border-width: 0;
  background: transparent;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  white-space: pre;
  word-wrap: normal;
  line-height: inherit;
  color: inherit;
  z-index: 2;
  position: relative;
  overflow: visible;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-variant-ligatures: contextual;
  font-feature-settings: "calt";
  font-variant-ligatures: contextual;
}
.CodeMirror-wrap pre.CodeMirror-line,
.CodeMirror-wrap pre.CodeMirror-line-like {
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: normal;
}
.CodeMirror-linebackground {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
}
.CodeMirror-linewidget {
  position: relative;
  z-index: 2;
  padding: 0.1px;
  /* Force widget margins to stay inside of the container */
}
.CodeMirror-rtl pre {
  direction: rtl;
}
.CodeMirror-code {
  outline: none;
}
/* Force content-box sizing for the elements where we expect it */
.CodeMirror-scroll,
.CodeMirror-sizer,
.CodeMirror-gutter,
.CodeMirror-gutters,
.CodeMirror-linenumber {
  box-sizing: content-box;
}
.CodeMirror-measure {
  position: absolute;
  width: 100%;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}
.CodeMirror-cursor {
  position: absolute;
  pointer-events: none;
}
.CodeMirror-measure pre {
  position: static;
}
div.CodeMirror-cursors {
  visibility: hidden;
  position: relative;
  z-index: 3;
}
div.CodeMirror-dragcursors {
  visibility: visible;
}
.CodeMirror-focused div.CodeMirror-cursors {
  visibility: visible;
}
.CodeMirror-selected {
  background: #d9d9d9;
}
.CodeMirror-focused .CodeMirror-selected {
  background: #d7d4f0;
}
.CodeMirror-crosshair {
  cursor: crosshair;
}
.CodeMirror-line::selection,
.CodeMirror-line > span::selection,
.CodeMirror-line > span > span::selection {
  background: #d7d4f0;
}
.CodeMirror-line::-moz-selection,
.CodeMirror-line > span::-moz-selection,
.CodeMirror-line > span > span::-moz-selection {
  background: #d7d4f0;
}
.cm-searching {
  background-color: #ffa;
  background-color: rgba(255, 255, 0, 0.4);
}
/* Used to force a border model for a node */
.cm-force-border {
  padding-right: 0.1px;
}
@media print {
  /* Hide the cursor when printing */
  .CodeMirror div.CodeMirror-cursors {
    visibility: hidden;
  }
}
/* See issue #2901 */
.cm-tab-wrap-hack:after {
  content: '';
}
/* Help users use markselection to safely style text background */
span.CodeMirror-selectedtext {
  background: none;
}

.cm-s-neat span.cm-comment {
  color: #a86;
}
.cm-s-neat span.cm-keyword {
  line-height: 1em;
  font-weight: bold;
  color: blue;
}
.cm-s-neat span.cm-string {
  color: #a22;
}
.cm-s-neat span.cm-builtin {
  line-height: 1em;
  font-weight: bold;
  color: #077;
}
.cm-s-neat span.cm-special {
  line-height: 1em;
  font-weight: bold;
  color: #0aa;
}
.cm-s-neat span.cm-variable {
  color: black;
}
.cm-s-neat span.cm-number,
.cm-s-neat span.cm-atom {
  color: #3a3;
}
.cm-s-neat span.cm-meta {
  color: #555;
}
.cm-s-neat span.cm-link {
  color: #3a3;
}
.cm-s-neat .CodeMirror-activeline-background {
  background: #e8f2ff;
}
.cm-s-neat .CodeMirror-matchingbracket {
  outline: 1px solid grey;
  color: black !important;
}

/*
  Name:       material
  Author:     Mattia Astorino (http://github.com/equinusocio)
  Website:    https://material-theme.site/
*/
.cm-s-material.CodeMirror {
  background-color: #263238;
  color: #EEFFFF;
}
.cm-s-material .CodeMirror-gutters {
  background: #263238;
  color: #546E7A;
  border: none;
}
.cm-s-material .CodeMirror-guttermarker,
.cm-s-material .CodeMirror-guttermarker-subtle,
.cm-s-material .CodeMirror-linenumber {
  color: #546E7A;
}
.cm-s-material .CodeMirror-cursor {
  border-left: 1px solid #FFCC00;
}
.cm-s-material.cm-fat-cursor .CodeMirror-cursor {
  background-color: #5d6d5c80 !important;
}
.cm-s-material .cm-animate-fat-cursor {
  background-color: #5d6d5c80 !important;
}
.cm-s-material div.CodeMirror-selected {
  background: rgba(128, 203, 196, 0.2);
}
.cm-s-material.CodeMirror-focused div.CodeMirror-selected {
  background: rgba(128, 203, 196, 0.2);
}
.cm-s-material .CodeMirror-line::selection,
.cm-s-material .CodeMirror-line > span::selection,
.cm-s-material .CodeMirror-line > span > span::selection {
  background: rgba(128, 203, 196, 0.2);
}
.cm-s-material .CodeMirror-line::-moz-selection,
.cm-s-material .CodeMirror-line > span::-moz-selection,
.cm-s-material .CodeMirror-line > span > span::-moz-selection {
  background: rgba(128, 203, 196, 0.2);
}
.cm-s-material .CodeMirror-activeline-background {
  background: rgba(0, 0, 0, 0.5);
}
.cm-s-material .cm-keyword {
  color: #C792EA;
}
.cm-s-material .cm-operator {
  color: #89DDFF;
}
.cm-s-material .cm-variable-2 {
  color: #EEFFFF;
}
.cm-s-material .cm-variable-3,
.cm-s-material .cm-type {
  color: #f07178;
}
.cm-s-material .cm-builtin {
  color: #FFCB6B;
}
.cm-s-material .cm-atom {
  color: #F78C6C;
}
.cm-s-material .cm-number {
  color: #FF5370;
}
.cm-s-material .cm-def {
  color: #82AAFF;
}
.cm-s-material .cm-string {
  color: #C3E88D;
}
.cm-s-material .cm-string-2 {
  color: #f07178;
}
.cm-s-material .cm-comment {
  color: #546E7A;
}
.cm-s-material .cm-variable {
  color: #f07178;
}
.cm-s-material .cm-tag {
  color: #FF5370;
}
.cm-s-material .cm-meta {
  color: #FFCB6B;
}
.cm-s-material .cm-attribute {
  color: #C792EA;
}
.cm-s-material .cm-property {
  color: #C792EA;
}
.cm-s-material .cm-qualifier {
  color: #DECB6B;
}
.cm-s-material .cm-variable-3,
.cm-s-material .cm-type {
  color: #DECB6B;
}
.cm-s-material .cm-error {
  color: #ffffff;
  background-color: #FF5370;
}
.cm-s-material .CodeMirror-matchingbracket {
  text-decoration: underline;
  color: white !important;
}

/**
    Name:       IDEA default theme
    From IntelliJ IDEA by JetBrains
 */
.cm-s-idea span.cm-meta {
  color: #808000;
}
.cm-s-idea span.cm-number {
  color: #0000FF;
}
.cm-s-idea span.cm-keyword {
  line-height: 1em;
  font-weight: bold;
  color: #000080;
}
.cm-s-idea span.cm-atom {
  font-weight: bold;
  color: #000080;
}
.cm-s-idea span.cm-def {
  color: #000000;
}
.cm-s-idea span.cm-variable {
  color: black;
}
.cm-s-idea span.cm-variable-2 {
  color: black;
}
.cm-s-idea span.cm-variable-3,
.cm-s-idea span.cm-type {
  color: black;
}
.cm-s-idea span.cm-property {
  color: black;
}
.cm-s-idea span.cm-operator {
  color: black;
}
.cm-s-idea span.cm-comment {
  color: #808080;
}
.cm-s-idea span.cm-string {
  color: #008000;
}
.cm-s-idea span.cm-string-2 {
  color: #008000;
}
.cm-s-idea span.cm-qualifier {
  color: #555;
}
.cm-s-idea span.cm-error {
  color: #FF0000;
}
.cm-s-idea span.cm-attribute {
  color: #0000FF;
}
.cm-s-idea span.cm-tag {
  color: #000080;
}
.cm-s-idea span.cm-link {
  color: #0000FF;
}
.cm-s-idea .CodeMirror-activeline-background {
  background: #FFFAE3;
}
.cm-s-idea span.cm-builtin {
  color: #30a;
}
.cm-s-idea span.cm-bracket {
  color: #cc7;
}
.cm-s-idea {
  font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
}
.cm-s-idea .CodeMirror-matchingbracket {
  outline: 1px solid grey;
  color: black !important;
}
.CodeMirror-hints.idea {
  font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;
  color: #616569;
  background-color: #ebf3fd !important;
}
.CodeMirror-hints.idea .CodeMirror-hint-active {
  background-color: #a2b8c9 !important;
  color: #5c6065 !important;
}

@font-face {
  font-family: "hzero-icon"; /* Project id 2264874 */
  src: url(/static/media/iconfont.0fe6e98c.eot); /* IE9 */
  src: url(/static/media/iconfont.0fe6e98c.eot) format('embedded-opentype'), 
       url(/static/media/iconfont.7237bc55.woff2) format('woff2'),
       url(/static/media/iconfont.64bd683c.woff) format('woff'),
       url(/static/media/iconfont.580bd4e5.ttf) format('truetype'), 
       url(/static/media/iconfont.bd6fa53a.svg#hzero-icon) format('svg'); /* iOS 4.1- */
}

.iconfont_hzero-icon__3yKDx {
  font-family: "hzero-icon" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconfont_hzero-icon-bangzhuwendang__2dyp9:before {
  content: "\E612";
}

.iconfont_hzero-icon-im__2HBjr:before {
  content: "\E61D";
}

.iconfont_hzero-icon-kuaijiejian__3Gesn:before {
  content: "\E622";
}

.iconfont_hzero-icon-message2__3e5_e:before {
  content: "\E607";
}

.iconfont_hzero-icon-message__1SWIG:before {
  content: "\EEE7";
}

.iconfont_hzero-icon-yunyingguanli__2XPQL:before {
  content: "\E60B";
}

.iconfont_hzero-icon-icon_shezhi__3YqFf:before {
  content: "\E62F";
}

.iconfont_hzero-icon-biaodanzujian-xialakuang__1KdVR:before {
  content: "\E6CA";
}

.iconfont_hzero-icon-biaodanzujian-biaoge__2oxym:before {
  content: "\E6F3";
}

.iconfont_hzero-icon-guanlianshebei__21l-l:before {
  content: "\E6FF";
}

.iconfont_hzero-icon-jiedianguanli__3QsEd:before {
  content: "\E70B";
}

.iconfont_hzero-icon-yuanquyunwei___7UL1:before {
  content: "\E72C";
}

.iconfont_hzero-icon-gongnengdingyi__3LYCr:before {
  content: "\E682";
}

.iconfont_hzero-icon-jichuguanli__2Mso-:before {
  content: "\E683";
}

.iconfont_hzero-icon-guanfangbanben__1Q2kZ:before {
  content: "\E602";
}

.iconfont_hzero-icon-icon_loading__1CyCZ:before {
  content: "\E66F";
}

.iconfont_hzero-icon-xingzhuang-xingxing__nwhsK:before {
  content: "\E649";
}

.iconfont_hzero-icon-guize__3_UyP:before {
  content: "\E611";
}

.iconfont_hzero-icon-main-printing__3A14W:before {
  content: "\E608";
}

.iconfont_hzero-icon-multi__3ecKp:before {
  content: "\E609";
}

.iconfont_hzero-icon-image__3v3cW:before {
  content: "\E641";
}

.iconfont_hzero-icon-license__1-ZE7:before {
  content: "\E64B";
}

.iconfont_hzero-icon-csv__2QPEG:before {
  content: "\E60A";
}

.iconfont_hzero-icon-text__1LmKS:before {
  content: "\E62E";
}

.iconfont_hzero-icon-map__2PAte:before {
  content: "\E61C";
}

.iconfont_hzero-icon-hzero-icon-pdf__23_IW:before {
  content: "\E60C";
}

.iconfont_hzero-icon-hzero-icon-ppt__3yJ3q:before {
  content: "\E60D";
}

.iconfont_hzero-icon-hzero-icon-html__eHjrO:before {
  content: "\E60E";
}

.iconfont_hzero-icon-hzero-icon-excel__2Tv-P:before {
  content: "\E60F";
}

.iconfont_hzero-icon-hzero-icon-word__1U8_K:before {
  content: "\E610";
}

.iconfont_hzero-icon-full-screen__3ipob:before {
  content: "\E621";
}

.iconfont_hzero-icon-close-other__22nL6:before {
  content: "\E603";
}

.iconfont_hzero-icon-close-all__18GNI:before {
  content: "\E605";
}

.iconfont_hzero-icon-bell__qdH25:before {
  content: "\E8E3";
}

.iconfont_hzero-icon-world-map__25u-Z:before {
  content: "\E62D";
}

.iconfont_hzero-icon-vat__4AS8f:before {
  content: "\E638";
}

.iconfont_hzero-icon-train__av7MI:before {
  content: "\E63F";
}

.iconfont_hzero-icon-taxi__1L3M7:before {
  content: "\EEE6";
}

.iconfont_hzero-icon-id-card__2clbZ:before {
  content: "\E600";
}

.iconfont_hzero-icon-cloud-service__2hGIW:before {
  content: "\E65E";
}

.iconfont_hzero-icon-commodity-management__2Ob-z:before {
  content: "\E65F";
}

.iconfont_hzero-icon-purchase__1X0I6:before {
  content: "\E660";
}

.iconfont_hzero-icon-import__2DbY9:before {
  content: "\E661";
}

.iconfont_hzero-icon-quality-business__330MN:before {
  content: "\E662";
}

.iconfont_hzero-icon-finance__Z56u4:before {
  content: "\E663";
}

.iconfont_hzero-icon-offer__14DgI:before {
  content: "\E664";
}

.iconfont_hzero-icon-expert-library__3AMy3:before {
  content: "\E665";
}

.iconfont_hzero-icon-questionnaire-management__1QmDY:before {
  content: "\E666";
}

.iconfont_hzero-icon-partner__2e7Ia:before {
  content: "\E667";
}

.iconfont_hzero-icon-sale__2L4X_:before {
  content: "\E668";
}

.iconfont_hzero-icon-group__23cr-:before {
  content: "\E66A";
}

.iconfont_hzero-icon-sourcing__ynIRQ:before {
  content: "\E66B";
}

.iconfont_hzero-icon-main-operation-record__6ydhY:before {
  content: "\E687";
}

.iconfont_hzero-icon-main-write-off-preview__1_n8L:before {
  content: "\E689";
}

.iconfont_hzero-icon-main-write-off__3aQkj:before {
  content: "\E68A";
}

.iconfont_hzero-icon-main-revoke__kJuN0:before {
  content: "\E68B";
}

.iconfont_hzero-icon-main-import__2qx3O:before {
  content: "\E68C";
}

.iconfont_hzero-icon-main-export__1RxQY:before {
  content: "\E68D";
}

.iconfont_hzero-icon-main-import-erp__2wKJB:before {
  content: "\E68F";
}

.iconfont_hzero-icon-dayin__3l6Ko:before {
  content: "\E690";
}

.iconfont_hzero-icon-main-delete__3aT2N:before {
  content: "\E691";
}

.iconfont_hzero-icon-main-receive__bAFoE:before {
  content: "\E692";
}

.iconfont_hzero-icon-main-receive-preview__2LCuj:before {
  content: "\E693";
}

.iconfont_hzero-icon-main-message__WwDgA:before {
  content: "\E694";
}

.iconfont_hzero-icon-main-urgent__1QvKZ:before {
  content: "\E695";
}

.iconfont_hzero-icon-main-revisions__Og5EY:before {
  content: "\E696";
}

.iconfont_hzero-icon-main-release__246gt:before {
  content: "\E697";
}

.iconfont_hzero-icon-main-batch-export__2rIi9:before {
  content: "\E698";
}

.iconfont_hzero-icon-main-batch-import__2FBVc:before {
  content: "\E699";
}

.iconfont_hzero-icon-main-find-supplier__2GnA5:before {
  content: "\E69A";
}

.iconfont_hzero-icon-main-relevance__1ST_h:before {
  content: "\E69B";
}

.iconfont_hzero-icon-main-cancel-relevance__37_CC:before {
  content: "\E69C";
}

.iconfont_hzero-icon-main-cancel-urgent__2C-VR:before {
  content: "\E69D";
}

.iconfont_hzero-icon-main-all-retract__yhBKH:before {
  content: "\E69E";
}

.iconfont_hzero-icon-main-batch-mapping__28L4W:before {
  content: "\E69F";
}

.iconfont_hzero-icon-main-batch-screen-supplier__2m3-W:before {
  content: "\E6A0";
}

.iconfont_hzero-icon-main-retract__Q5leG:before {
  content: "\E6A1";
}

.iconfont_hzero-icon-main-cancel-mapping__3C_Qs:before {
  content: "\E6A2";
}

.iconfont_hzero-icon-main-cancel__1227N:before {
  content: "\E6A3";
}

.iconfont_hzero-icon-main-all-open__eWYoc:before {
  content: "\E6A4";
}

.iconfont_hzero-icon-main-upper-shelf__1HPx8:before {
  content: "\E6A5";
}

.iconfont_hzero-icon-main-submission__3UfQI:before {
  content: "\E6A6";
}

.iconfont_hzero-icon-main-batch-added-supplier__3YHT8:before {
  content: "\E6A7";
}

.iconfont_hzero-icon-main-agree-cooperation__gvKgq:before {
  content: "\E6A8";
}

.iconfont_hzero-icon-refresh___21an:before {
  content: "\E6A9";
}

.iconfont_hzero-icon-main-adjustment-time__1W9I-:before {
  content: "\E6AA";
}

.iconfont_hzero-icon-main-synchronization__3_H_0:before {
  content: "\E6AB";
}

.iconfont_hzero-icon-main-download__39sbt:before {
  content: "\E6AC";
}

.iconfont_hzero-icon-main-check__2cVdT:before {
  content: "\E6AD";
}

.iconfont_hzero-icon-main-adopt__2lggw:before {
  content: "\E6AE";
}

.iconfont_hzero-icon-main-added-top-list__2rJtp:before {
  content: "\E6AF";
}

.iconfont_hzero-icon-main-confirm-bid-opening__3Yed5:before {
  content: "\E6B0";
}

.iconfont_hzero-icon-main-modify-directory__jdLHa:before {
  content: "\E6B1";
}

.iconfont_hzero-icon-main-added-top__2RI7G:before {
  content: "\E6B2";
}

.iconfont_hzero-icon-main-invitation-cooperation__2l07T:before {
  content: "\E6B3";
}

.iconfont_hzero-icon-main-added-top-scoring-elements__2cH_1:before {
  content: "\E6B4";
}

.iconfont_hzero-icon-main-return-first-trial__28lwg:before {
  content: "\E6B5";
}

.iconfont_hzero-icon-main-lower-shelf__MheYL:before {
  content: "\E6B6";
}

.iconfont_hzero-icon-main-invitation-register__ru13x:before {
  content: "\E6B7";
}

.iconfont_hzero-icon-main-open__1MPkB:before {
  content: "\E6B8";
}

.iconfont_hzero-icon-main-list-urgent__mUbsJ:before {
  content: "\E6B9";
}

.iconfont_hzero-icon-main-read__2_CJ2:before {
  content: "\E6BA";
}

.iconfont_hzero-icon-main-reissued-password__2AJ1d:before {
  content: "\E6BB";
}

.iconfont_hzero-icon-main-counter-offer__3piv6:before {
  content: "\E6BC";
}

.iconfont_hzero-icon-main-list-cancel-urgent__1ldpX:before {
  content: "\E6BD";
}

.iconfont_hzero-icon-main-quote-masterplate__38lKQ:before {
  content: "\E6BE";
}

.iconfont_hzero-icon-main-reinquiry__ltBbK:before {
  content: "\E6BF";
}

.iconfont_hzero-icon-main-quote__3oeqG:before {
  content: "\E6C0";
}

.iconfont_hzero-icon-main-tovoid__p02xn:before {
  content: "\E6C1";
}

.iconfont_hzero-icon-main-ending-counter-offer__2xm87:before {
  content: "\E6C2";
}

.iconfont_hzero-icon-main-quote-procurement-application__1GwSj:before {
  content: "\E6C3";
}

.iconfont_hzero-icon-main-quote-source-finding__2CeSf:before {
  content: "\E6C4";
}

.iconfont_hzero-icon-main-mapping__2O6oY:before {
  content: "\E6C5";
}

.iconfont_hzero-icon-main-handover__2UkN_:before {
  content: "\E6C6";
}

.iconfont_hzero-icon-main-reset__2ElM_:before {
  content: "\E6C7";
}

.iconfont_hzero-icon-bidding__3KxB5:before {
  content: "\E66C";
}

.iconfont_hzero-icon-main-parity-assistant__2k-1-:before {
  content: "\E6C8";
}

.iconfont_hzero-icon-Protocol-Management__1BYn3:before {
  content: "\E6C9";
}

.iconfont_hzero-icon-cert__2aFG3:before {
  content: "\E640";
}

.iconfont_hzero-icon-look-over__1wWJD:before {
  content: "\E9DB";
}

.iconfont_hzero-icon-tender__LRTzZ:before {
  content: "\E9DC";
}

.iconfont_hzero-icon-participate-in__3qPlq:before {
  content: "\E9DD";
}

.iconfont_hzero-icon-invite-tenders__87epR:before {
  content: "\E9DE";
}

.iconfont_hzero-icon-Enable__1CInd:before {
  content: "\EA38";
}

.iconfont_hzero-icon-Application-Market__2BIPm:before {
  content: "\EA3A";
}

.iconfont_hzero-icon-Shopping-Cart__3Sptx:before {
  content: "\EA3B";
}

.iconfont_hzero-icon-Opened__3UcPl:before {
  content: "\EA3C";
}

.iconfont_hzero-icon-Service-Opening-Record__3-QS6:before {
  content: "\EA3D";
}

.iconfont_hzero-icon-WeChat__3pwos:before {
  content: "\EA3E";
}

.iconfont_hzero-icon-Authentication-Information__2xr4C:before {
  content: "\EA3F";
}

.iconfont_hzero-icon-Telephone__2fC2o:before {
  content: "\EA40";
}

.iconfont_hzero-icon-Application-Store__29N1Y:before {
  content: "\EA49";
}

.iconfont_hzero-icon-oc-Import__2hjhb:before {
  content: "\EA4A";
}

.iconfont_hzero-icon-Invoice-Inspection__1645f:before {
  content: "\EA4B";
}

.iconfont_hzero-icon-Seal__1GRXN:before {
  content: "\EA4C";
}

.iconfont_hzero-icon-app__C7di0:before {
  content: "\EA4D";
}

.iconfont_hzero-icon-link-invoice__37kYH:before {
  content: "\EA4E";
}

.iconfont_hzero-icon-Generate-account__3Kv8y:before {
  content: "\EA50";
}

.iconfont_hzero-icon-erweima__3531S:before {
  content: "\E6D0";
}

.iconfont_hzero-icon-dianhua__HvFow:before {
  content: "\E6D1";
}

.iconfont_hzero-icon-scan__3cii3:before {
  content: "\E79E";
}

.iconfont_hzero-icon-up__3UIIg:before {
  content: "\E628";
}

.iconfont_hzero-icon-top__2DkPn:before {
  content: "\E629";
}

.iconfont_hzero-icon-down__2EdlP:before {
  content: "\E62A";
}

.iconfont_hzero-icon-bottom__23Eyp:before {
  content: "\E62B";
}

.iconfont_hzero-icon-CombinedShape__lSD8v:before {
  content: "\EA51";
}

.iconfont_hzero-icon-jieshu__2r99Y:before {
  content: "\E6D2";
}

.iconfont_hzero-icon-tishi__6uWNk:before {
  content: "\E62C";
}

.iconfont_hzero-icon-re-rating-all__1CMsH:before {
  content: "\E6D5";
}

.iconfont_hzero-icon-copy1__1_fMU:before {
  content: "\E6D6";
}

.iconfont_hzero-icon-yijia__1w4em:before {
  content: "\E6D7";
}

.iconfont_hzero-icon-yinyongjiage__kD0gM:before {
  content: "\E6D8";
}

.iconfont_hzero-icon-xinxitishi__2JOdS:before {
  content: "\E6DB";
}

.iconfont_hzero-icon-photo__1a77P:before {
  content: "\E601";
}

.iconfont_hzero-icon-edit__2zS_o:before {
  content: "\E604";
}

.iconfont_hzero-icon-copy__2-ctM:before {
  content: "\E606";
}

.iconfont_hzero-icon-shoppingCar__1TaNy:before {
  content: "\E655";
}

.iconfont_hzero-icon-work-s___YjX2:before {
  content: "\E618";
}

.iconfont_hzero-icon-news-s__8IqiK:before {
  content: "\E619";
}

.iconfont_hzero-icon-scheduling-service__1P3Er:before {
  content: "\E613";
}

.iconfont_hzero-icon-dispatch-platform__3Zryx:before {
  content: "\E614";
}

.iconfont_hzero-icon-service-governance__2ji81:before {
  content: "\E615";
}

.iconfont_hzero-icon-workflow-setting__3zhTV:before {
  content: "\E616";
}

.iconfont_hzero-icon-basic-data-management__3Xcj0:before {
  content: "\E617";
}

.iconfont_hzero-icon-interface-platform__2nzII:before {
  content: "\E61A";
}

.iconfont_hzero-icon-portal-management__EKAUi:before {
  content: "\E61B";
}

.iconfont_hzero-icon-workflow__xxoLt:before {
  content: "\E61E";
}

.iconfont_hzero-icon-system-management__1fwo4:before {
  content: "\E61F";
}

.iconfont_hzero-icon-message-management__ap5I9:before {
  content: "\E620";
}

.iconfont_hzero-icon-reporting-platform__3RHRl:before {
  content: "\E645";
}

.iconfont_hzero-icon-development-management__3RXtI:before {
  content: "\E646";
}

.iconfont_hzero-icon-transaction-management__3nphD:before {
  content: "\E647";
}

.iconfont_hzero-icon-document-management__1H-Sa:before {
  content: "\E648";
}

.iconfont_hzero-icon-organization-management__3V5hD:before {
  content: "\E64D";
}

.iconfont_hzero-icon-business-settings__18IUL:before {
  content: "\E651";
}

.iconfont_hzero-icon-credit-platform__kChnG:before {
  content: "\E652";
}

.iconfont_hzero-icon-governance-platform__1DGom:before {
  content: "\E654";
}

.iconfont_hzero-icon-cloud-management__2AO08:before {
  content: "\E656";
}

.iconfont_hzero-icon-interface-management__1U5Qj:before {
  content: "\E657";
}

.iconfont_hzero-icon-cloud-partner__2Z3cf:before {
  content: "\E658";
}

.iconfont_hzero-icon-data-platform__1qE-M:before {
  content: "\E659";
}

.iconfont_hzero-icon-store-management__3U1ie:before {
  content: "\E65A";
}

.iconfont_hzero-icon-enterprise-certification__i_9ZA:before {
  content: "\E65B";
}

.iconfont_hzero-icon-workflow1__10rEC:before {
  content: "\E65C";
}

.iconfont_hzero-icon-notice__dz92l:before {
  content: "\E65D";
}

